import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
  TabPanel,
  TabList,
  tabClasses,
} from "@mui/joy";
import { useParams } from "react-router-dom";
import CasesTable from "../components/Cases/CasesTable";
import DeleteCasesTable from "../components/Cases/DeleteCasesTable";
import { useState } from "react";
import { DeleteCaseRequest } from "../models/Jobs/JobRequest";

const CasesPage = () => {
  const { organizationId } = useParams();
  const [newCasesForDeletion, setNewCasesForDeletion] = useState<
    DeleteCaseRequest[]
  >([]);
  return (
    <>
      <Stack maxHeight={"92vh"}>
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2">Cases</Typography>
        </Box>
        <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
          <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
            <Card>
              <CasesTable
                organizationId={organizationId ?? ""}
                newCasesForDeletion={newCasesForDeletion}
                setNewCasesForDeletion={setNewCasesForDeletion}
              />
            </Card>
          </Grid>
          <Grid xs={12} lg={3} display={"flex"} maxHeight={"100%"}>
            <Card sx={{ width: "100%", padding: 0 }}>
              <Tabs
                defaultValue={0}
                sx={{
                  maxHeight: "100%",
                  borderRadius: "sm",
                  overflow: "auto",
                }}
              >
                <TabList
                  disableUnderline
                  tabFlex={1}
                  sx={{
                    [`& .${tabClasses.root}`]: {
                      fontSize: "sm",
                      fontWeight: "md",
                      [`&[aria-selected="true"]`]: {
                        bgcolor: "background.surface",
                      },
                      [`&.${tabClasses.focusVisible}`]: {
                        outlineOffset: "-4px",
                      },
                    },
                  }}
                >
                  <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                    Delete cases
                  </Tab>
                </TabList>
                <TabPanel
                  value={0}
                  sx={{
                    flex: 1,
                    overflow: "auto",
                    maxHeight: "100%",
                    paddingTop: 0,
                  }}
                >
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      maxHeight: "100%",
                    }}
                  >
                    <DeleteCasesTable
                      organizationId={organizationId ?? ""}
                      newCasesForDeletion={newCasesForDeletion}
                      setNewCasesForDeletion={setNewCasesForDeletion}
                    />
                  </Card>
                </TabPanel>
              </Tabs>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default CasesPage;
