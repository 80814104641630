import { AxiosResponse } from "axios";
import { ClientConfiguration } from "../models/ClientConfiguration/ClientConfiguration";
import useApi from "../services/ApiService";

interface GetClientConfigurationsQueryOptions {
  ids?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}

export class ClientConfigurationRepository {
  private api = useApi();

  async getClientConfiguration(id: string): Promise<ClientConfiguration> {
    try {
      const response: AxiosResponse<ClientConfiguration> = await this.api.get(
        `/api/ClientConfigurations/${id}`,
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch client configuration with id: ${id}`);
    }
  }

  async getClientConfigurations(
    organizationId: string,
    options: GetClientConfigurationsQueryOptions = {},
  ): Promise<ClientConfiguration[]> {
    const {
      ids = null,
      pageNumber = null,
      pageSize = null,
      orderBy = "Name",
      descending = false,
    } = options;

    try {
      const response: AxiosResponse<ClientConfiguration[]> = await this.api.get(
        "/api/ClientConfigurations",
        {
          params: {
            OrganizationId: organizationId,
            Id: ids,
            PageNumber: pageNumber,
            PageSize: pageSize,
            OrderBy: orderBy,
            Descending: descending,
          },
        },
      );

      return response.data;
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch client configurations");
    }
  }

  async updateClientConfiguration(
    id: string,
    updatedConfiguration: ClientConfiguration,
  ): Promise<ClientConfiguration> {
    try {
      const response: AxiosResponse<ClientConfiguration> = await this.api.put(
        `/api/ClientConfigurations/${id}`,
        updatedConfiguration,
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to update client configuration with id: ${id}`);
    }
  }
  async createClientConfiguration(
    newConfiguration: ClientConfiguration,
  ): Promise<ClientConfiguration> {
    try {
      const { id, dateCreated, ...newConfigPayload } = newConfiguration;
      const response: AxiosResponse<ClientConfiguration> = await this.api.post(
        `/api/ClientConfigurations/`,
        newConfigPayload,
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to create client configuration with name: ${newConfiguration.name}`,
      );
    }
  }
  async deleteConfig(configId: string): Promise<void> {
    try {
      return await this.api.delete(`/api/ClientConfigurations/${configId}`);
    } catch (error) {
      // Handle error
      throw new Error("Failed to delete configuration");
    }
  }

  async setDefaultConfig(configId: string): Promise<void> {
    try {
      return await this.api.patch(`/api/ClientConfigurations/SetAsDefaultConfiguration/${configId}`);
    } catch (error) {
      // Handle error
      throw new Error("Failed to set configuration as default");
    }
  }
}

export default ClientConfigurationRepository;
