const { DateTime } = require("luxon");

export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export const convertUtcToLocal = (utcTime: string): string => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTime = DateTime.fromISO(utcTime, { zone: "utc" })
    .setZone(currentTimeZone)
    .toLocal();
  return localTime.toJSDate().toLocaleString();
};
export const roundToTwoDecimalPoints = (value: number): string => {
  return value.toFixed(2);
};
export const getFormattedDate = (
  date: string,
  format = "dd/MM/yyyy",
): string => {
  const luxonDate = DateTime.fromISO(date);
  return luxonDate.toFormat(format);
};

export const getDateTimeString = (date: string | undefined, time: string | undefined) => {
  const defaultTime = "00:00";
  if (date) {
    const timeToUse = time || defaultTime;
    const localDateTime = new Date(`${date}T${timeToUse}`);
    return localDateTime.toISOString().slice(0, 19);
  }
  return null;
};

export const formatDate = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatTime = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};


