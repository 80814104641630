import useApi from "../services/ApiService";
import { Paging } from "../models/Paging";
import {DiscoveryCycle} from "../models/Statistics/DiscoveryCycle";
import {MigrationType} from "../models/Statistics/MigrationType";
import {MigrationEventStepType} from "../models/Statistics/MigrationEventStepType";
import {getDateTimeString} from "../helpers/StringFormatters";
import {DiscoveryCycleFilterRangeType} from "../pages/DiscvoeryCyclesPage";

export interface DiscoveryCycleQueryParams {
  clientIds: string[];
  eventSteps: MigrationEventStepType[];
  rangeType: DiscoveryCycleFilterRangeType;
  fromDate?: string;
  fromTime?: string;
  toDate?: string;
  toTime?: string;
}

interface GetDiscoveryCyclesQueryOptions {
  organizationId?: string;
  migrationType?: MigrationType;
  params?: DiscoveryCycleQueryParams;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}

export class DiscoveryCyclesRepository {
  private api = useApi();

  async getDiscoveryCycles(
    options: GetDiscoveryCyclesQueryOptions = {}
  ): Promise<{ data: DiscoveryCycle[]; paging: Paging | null }> {
    const {
      migrationType = null,
      params = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;
    
    try {
      const startDate = getDateTimeString(params?.fromDate, params?.fromTime);
      const endDate = getDateTimeString(params?.toDate, params?.toTime);
      const response = await this.api.get("/api/Cycles", {
        params: {
          MigrationType: migrationType,
          ClientIds: params?.clientIds,
          EventSteps : params?.eventSteps,
          FilterRangeType: params?.rangeType,
          StartDate: startDate,
          EndDate: endDate,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }

      return { data, paging };
    } catch (error) {
      throw new Error("Failed to fetch discovery cycles");
    }
  }
}
