import { JobRequest, transformJobRequest } from "../models/Jobs/JobRequest";
import useApi from "../services/ApiService";

export class JobsRepository {
  private api = useApi();
  async getJobs(): Promise<JobRequest[]> {
    try {
      const response = await this.api.get("/api/jobs/all");
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(`Failed to get jobs: ${error}`);
    }
  }
  async deleteJob(jobId: string): Promise<void> {
    try {
      await this.api.delete(`/api/jobs/${jobId}`);
    } catch (error) {
      throw new Error(`Failed to abort job: ${error}`);
    }
  }
}
export default JobsRepository;
