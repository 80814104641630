import { ColorPaletteProp } from "@mui/joy";

export enum JobType {
  DeleteEntity = "deleteEntity",
}
export const JobTypeLabels: Record<JobType, string> = {
  [JobType.DeleteEntity]: "Delete",
};

export const JobTypeColors: Record<JobType, ColorPaletteProp> = {
  [JobType.DeleteEntity]: "danger",
};
