import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Divider, Modal, ModalDialog } from "@mui/joy";
import Button from "@mui/joy/Button";
import DialogActions from "@mui/joy/DialogActions";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import React from "react";
import { JobRequest } from "../../models/Jobs/JobRequest";
import { JobTypeLabels } from "../../models/Jobs/JobType";
import { JobEntityTypeLabels } from "../../models/Jobs/JobEntityType";
import JobInfoView from "./JobInfoView";
import { JobRequestStatus } from "../../models/Jobs/JobRequestStatus";

interface AbortJobModalProps {
  open: boolean;
  jobRequest: JobRequest;
  onClose: () => void;
  onConfirm: () => void;
}

const AbortJobModal: React.FC<AbortJobModalProps> = ({
  open,
  jobRequest,
  onClose,
  onConfirm,
}) => {
  const getMessage = () => {
    return `Are you sure you want to ${jobRequest.status === JobRequestStatus.Completed ? "remove" : "abort"} ${JobTypeLabels[jobRequest.type]} job for ${JobEntityTypeLabels[jobRequest.entityType]}?`;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmation required
        </DialogTitle>
        <Divider />
        <DialogContent>
          {getMessage()}
          <JobInfoView jobRequest={jobRequest} />{" "}
        </DialogContent>
        <DialogActions>
          <Button variant="solid" onClick={() => onConfirm()} color="danger">
            {jobRequest.status === JobRequestStatus.Completed
              ? "Remove"
              : "Abort"}
          </Button>
          <Button variant="plain" color="neutral" onClick={() => onClose()}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default AbortJobModal;
