import { Box, Button, Skeleton, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import ClientConfigurationsTable from "../components/ClientConfiguration/ClientConfigurationsTable";
import ConfirmSaveChangesModal from "../components/common/ConfirmSaveChangesModal";
import { ClientConfiguration } from "../models/ClientConfiguration/ClientConfiguration";
import ClientConfigurationsRepository from "../repositories/ClientConfigurationsRepository";
interface ClientConfigurationsPageProps {
  organizationId?: string;
}
const ClientConfigurationsPage: React.FC<ClientConfigurationsPageProps> = ({
  organizationId,
}) => {
  const configurationsRepository = new ClientConfigurationsRepository();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  const [configs, setConfigs] = React.useState<ClientConfiguration[]>([]);
  const [configToDeleteId, setConfigToDeleteId] = useState<string | null>(null);
  const [defaultConfigId, setDefaultConfigId] = useState<string | null>(null);
  const [confirmDeletionModalOpened, setConfirmDeletionModalOpened] = useState<boolean>(false);

  const configurationsQuery = useQuery(
    ["clientConfigurations", organizationId],
    async () => {
      const configurations =
        await configurationsRepository.getClientConfigurations(
          organizationId || "",
        );
      return configurations;
    },
    {
      staleTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchIntervalInBackground: true,
    },
  );
  
  useEffect(() => {
    if (configurationsQuery.data) {
      setConfigs(configurationsQuery.data);
    }
  }, [organizationId, configurationsQuery.data]);
  
  const { isLoading, isError } = configurationsQuery;

  const handleConfirmDelete = async () => {
    if (configToDeleteId) {
      try {
        await configurationsRepository.deleteConfig(configToDeleteId);
        await queryClient.invalidateQueries(["clientConfigurations", organizationId]);
      } catch (error) {
        console.error(error);
      }
    }
    setConfigToDeleteId(null);
    setConfirmDeletionModalOpened(false);
  };

  const handleSetAsDefaultConfig = async () => {
    setDefaultConfigId(null);
    if (defaultConfigId) {
      try {
        await configurationsRepository.setDefaultConfig(defaultConfigId);
        await queryClient.invalidateQueries(["clientConfigurations", organizationId]);
      } catch (error) {
        console.error(error);
      }
    }
  };
  
  const handleSetDefaultConfig = (id: string) => {
    setDefaultConfigId(id);
    setConfigs((prevConfigs) =>
      prevConfigs.map((config) => ({
        ...config,
        isDefault: config.id === id,
      }))
    );
  };
  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Client Configurations</Typography>
        <Stack direction="row" spacing={1}>
          <Skeleton variant="inline" loading={isLoading || isError}>
            <Button
              color="primary"
              size="sm"
              disabled={ defaultConfigId === null }
              sx={{ pr: "2" }}
              onClick={
                async () => await handleSetAsDefaultConfig()
              }
            >
              Save Changes
            </Button>
          </Skeleton>
          <Skeleton variant="inline" loading={isLoading || isError}>
            <Button
              color="primary"
              size="sm"
              onClick={() =>
                navigate(`/organization/${organizationId}/client-configuration/create`)
              }
            >
              Create Configuration
            </Button>
          </Skeleton>
        </Stack>
      </Box>
      <Skeleton variant="inline" loading={isLoading || isError}>
        <ClientConfigurationsTable
          organizationId={organizationId ?? ""}
          configurations={configs}
          setDefaultConfigId={handleSetDefaultConfig}
          onDeleteConfig={function (configId: string): void {
            setConfirmDeletionModalOpened(true);
            setConfigToDeleteId(configId);
          }}
        />
      </Skeleton>
      <ConfirmSaveChangesModal
        isOpen={confirmDeletionModalOpened}
        onClose={function (): void {
          setConfigToDeleteId(null);
          setConfirmDeletionModalOpened(false);
        }}
        onSaveChanges={async function (): Promise<void> {
          if (configToDeleteId) {
            await handleConfirmDelete();
            return;
          }
        }}
        message={() => {
          return "Are you sure you want to delete this configuration?";
        }}
      />
    </>
  );
};

export default ClientConfigurationsPage;
