import { ColorPaletteProp } from "@mui/joy";

export enum JobEntityType {
  Case = "Case",
  Intake = "Intake",
  Provider = "Provider",
  FirmDocumentsFolder = "FirmDocumentsFolder",
  TemplatesLibrary = "TemplatesLibrary",
}
export const JobEntityTypeLabels: Record<JobEntityType, string> = {
  [JobEntityType.Case]: "Case",
  [JobEntityType.Intake]: "Intake",
  [JobEntityType.Provider]: "Provider",
  [JobEntityType.FirmDocumentsFolder]: "Firm Documents",
  [JobEntityType.TemplatesLibrary]: "Templates",
};

export const JobEntityTypeColors: Record<JobEntityType, ColorPaletteProp> = {
  [JobEntityType.Case]: "primary",
  [JobEntityType.Intake]: "success",
  [JobEntityType.Provider]: "lime",
  [JobEntityType.FirmDocumentsFolder]: "danger",
  [JobEntityType.TemplatesLibrary]: "warning",
};
