import { Chip } from "@mui/joy";
import React from "react";
import {
  JobType,
  JobTypeColors,
  JobTypeLabels,
} from "../../models/Jobs/JobType";

type ChipBreakpoint = "sm" | "md" | "lg";

interface JobTypeChipProps {
  type: JobType;
  size?: ChipBreakpoint;
}

const JobTypeChip: React.FC<JobTypeChipProps> = ({ type, size = "sm" }) => {
  return (
    <Chip variant="soft" size={size} color={JobTypeColors[type]}>
      {JobTypeLabels[type]}
    </Chip>
  );
};

export default JobTypeChip;
