import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";
import { getFormattedDate } from "../../helpers";
import { Order, compareBy, stableSort } from "../../helpers/TableHelpers";
import { OrganizationWithSecret } from "../../models/Organization";
import OrganizationsRepository from "../../repositories/OrganizationsRepository";
import UsersRepository from "../../repositories/UsersRepository";
import { User } from "../../models/User";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import GenericAutocomplete from "../common/GenericAutocomplete";
import { useAuth } from "../../contexts/AuthContext";
import SecretViewer from "../common/SecretViewer";

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Option,
  Sheet,
  Table,
  Typography,
  IconButton,
  Select,
  Skeleton,
} from "@mui/joy";

import {
  CloseRounded,
  Delete,
  Search,
} from "@mui/icons-material";

interface OrganizationsTableProps {
  onDeleteOrganization: (configId: string) => void;
}

const OrganizationsTable: React.FC<OrganizationsTableProps> = ({
  onDeleteOrganization
}) => {
  const { getUserId } = useAuth();
  const navigate = useNavigate();
  const usersRepository = new UsersRepository();
  const organizationsRepository = new OrganizationsRepository();
  
  const [order, setOrder] =
    React.useState<Order>("desc");
  const [sort, setSort] =
    React.useState<string>("dateCreated");
  const [selectedUserId, setSelectedUserId] =
    React.useState<string | null>(null);
  const [searchQuery, setSearchQuery] =
    useState("");
  const [organizations, setOrganizations] =
    useState<OrganizationWithSecret[]>([]);
  const [users, setUsers] =
    useState<User[]>([]);

  const usersQuery = useQuery(
    "users",
    async () => {
      return await usersRepository.getUsers();
    },
    {
      staleTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000 ,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    setSelectedUserId(getUserId());
  }, []);

  useEffect(() => {
    if (usersQuery.data?.data)
      setUsers(usersQuery.data.data);
  }, [usersQuery.data]);

  useEffect(() => {
    (async () => {
      if (selectedUserId) {
        const organizations = await organizationsRepository.getOrganizations(selectedUserId);
        setOrganizations(organizations.data);
      }
    })();
  }, [selectedUserId]);
  
  const userOptions = users.map(user => ({
    id: user.id,
    label: user.username,
  }));

  const handleUserChange = (newValue: string | null) => {
    setSelectedUserId(newValue);
    if (newValue == null) {
      (async () => {
        const organizations = await organizationsRepository.getOrganizations(null);
        setOrganizations(organizations.data);
      })();
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSort('');
    setSearchQuery(event.target.value);
  };

  const searchOptions = {
    keys: ["name", "intactId"],
    includeScore: true,
    threshold: 0.5,
    caseSensitive: false,
  };

  const fuse = new Fuse(organizations, searchOptions);
  const searchResults = fuse.search(searchQuery);

  let filteredOrganizations = searchQuery
    ? searchResults.map((result) => result.item)
    : organizations;

  const sortedResults = sort
    ? stableSort<OrganizationWithSecret>(filteredOrganizations, compareBy(order, sort))
    : filteredOrganizations;
  
  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <Skeleton variant="inline" sx={{ flex: 1 }} loading={usersQuery.isFetching || usersQuery.isError}>
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Search for organization</FormLabel>
            <Input
              size="sm"
              placeholder="Search"
              startDecorator={<Search />}
              onChange={handleSearchChange}
            />
          </FormControl>
        </Skeleton>

        <Skeleton variant="inline" loading={usersQuery.isFetching || usersQuery.isError}>
          <FormControl size="sm" sx={{ width: 300 }}>
            <FormLabel>Assigned to</FormLabel>
            <Select
              value={selectedUserId ?? ""}
              onChange={(e, newValue) => handleUserChange(newValue)}
              sx={{ minWidth: 160 }}
              endDecorator={
                selectedUserId && (
                  <IconButton
                    size="xs"
                    variant="plain"
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onClick={() => {
                      handleUserChange(null);
                    }}
                  >
                    <CloseRounded />
                  </IconButton>
                )
              }
            >
              {userOptions.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.label}
                </Option>
              ))}
            </Select>

          </FormControl>
        </Skeleton>
        
      </Box>
      <Sheet
        className="OrganizationsTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={usersQuery.isFetching || usersQuery.isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
            <tr>
              <th
                style={{
                  width: "2%",
                  padding: "12px 6px",
                }}
              />
              <th
                style={{
                  width: "10%",
                  padding: "12px 6px",
                }}
              >
                <SortableTableColumnHeader
                  label={"Intact ID"}
                  sortProperty={"intactId"}
                  getSort={() => sort}
                  setSort={(newSort) => setSort(newSort)}
                  getOrder={() => order}
                  setOrder={(newOrder) => setOrder(newOrder)}
                />
              </th>
              <th
                style={{width: "20%", padding: "12px 6px"}}
              >
                <SortableTableColumnHeader
                  label={"Name"}
                  sortProperty={"name"}
                  getSort={() => sort}
                  setSort={(newSort) => setSort(newSort)}
                  getOrder={() => order}
                  setOrder={(newOrder) => setOrder(newOrder)}
                />
              </th>
              <th
                style={{width: "25%", padding: "12px 6px"}}
              >
                Secret
              </th>
              <th
                style={{width: "12%", padding: "12px 6px"}}
              >
                <SortableTableColumnHeader
                  label={"Date Created"}
                  sortProperty={"dateCreated"}
                  getSort={() => sort}
                  setSort={(newSort) => setSort(newSort)}
                  getOrder={() => order}
                  setOrder={(newOrder) => setOrder(newOrder)}
                />
              </th>
              <th
                style={{
                  width: "20%",
                  padding: "12px 6px"
                }}
              >
                Assigned to
              </th>
              <th
                style={{
                  width: "5%",
                  minWidth: 140,
                  padding: "12px 6px"
                }}
              />
            </tr>
            </thead>
            <tbody>
            {sortedResults.map((organization) => {
              let assignedUser = {id: organization.assignedUser.id, label: organization.assignedUser.username}

              const handleSelectUser = async (selectedOption: { id: string; label: string }, organizationId: string) => {
                assignedUser.id = selectedOption.id;
                assignedUser.label = selectedOption.label;

                try {
                  await organizationsRepository.patchOrganizationAssignedUser(organizationId, selectedOption.id);
                } catch (error) {
                  console.error(error);
                }
              };
              
              return (
                <tr
                  key={organization.id}
                  onDoubleClick={() =>
                    navigate(`/organization/${organization.id}`)
                  }
                >
                  <td style={{textAlign: "center", width: 60}}></td>
                  <td>
                    <Typography level="body-sm" sx={{wordBreak: "break-all"}}>
                      {organization.intactId}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body-md" sx={{wordBreak: "break-word"}}>
                      {organization.name}
                    </Typography>
                  </td>
                  <td>
                    <SecretViewer organization={organization}/>
                  </td>
                  <td>
                    <Typography level="body-sm">
                      {getFormattedDate(organization.dateCreated as string)}
                    </Typography>
                  </td>
                  <td>
                    <GenericAutocomplete
                      options={userOptions}
                      inputValue={assignedUser}
                      setValue={(selectedOption) => handleSelectUser(selectedOption, organization.id)}
                      placeholder="Assigned user"
                      size="sm"
                    />
                  </td>
                  <td>
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      color="danger"
                      onClick={() => onDeleteOrganization(organization.id ?? "")}
                    >
                      <Delete/>
                    </IconButton>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </Table>
        </Skeleton>
      </Sheet>
    </React.Fragment>
  );
};

export default OrganizationsTable;
