import { AxiosResponse } from "axios";
import { Case, CaseWithStatistics } from "../models/Case";
import { CaseFile } from "../models/CaseFile";
import { Paging } from "../models/Paging";
import useApi from "../services/ApiService";
import { MigrationFlagStatus } from "../models/Flags/MigrationFlagStatus";
import { CaseFileFilters } from "../models/CaseFileFilters";
import {
  DeleteCaseRequest,
  transformJobRequest,
} from "../models/Jobs/JobRequest";

interface GetCasesQueryOptions {
  organizationId?: string;
  status?: string[] | null;
  createdBy?: string[];
  caseNumber?: string[] | null;
  ids?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}
interface GetCaseFilesForCaseQueryOptions {
  status?: string[] | null;
  extension?: string[] | null;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
  nestedFolder1?: string | null;
  nestedFolder2?: string | null;
  filePath?: string | null;
  minFileSize?: number | null;
  maxFileSize?: number | null;
  type?: number | null;
}

export class CasesRepository {
  private api = useApi();

  async getCases(
    options: GetCasesQueryOptions = {}
  ): Promise<{ data: Case[]; paging: Paging | null }> {
    const {
      status = null,
      createdBy = null,
      caseNumber = null,
      ids = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;

    try {
      const response = await this.api.get("/api/Cases", {
        params: {
          Status: status,
          CreatedBy: createdBy,
          CaseNumber: caseNumber,
          Id: ids,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }

      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch cases");
    }
  }

  async getCase(id: string): Promise<CaseWithStatistics> {
    try {
      const response = await this.api.get(`/api/Cases/${id}`);
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch case with id: ${id}`);
    }
  }
  async getCaseFilesForCase(
    id: string,
    options: GetCaseFilesForCaseQueryOptions = {}
  ): Promise<{ data: CaseFile[]; paging: Paging | null }> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      nestedFolder1 = null,
      nestedFolder2 = null,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
      type = null,
    } = options;
    try {
      const response = await this.api.get(`/api/Cases/${id}/files`, {
        params: {
          Status: status,
          Extension: extension,
          NestedFolder1: nestedFolder1,
          NestedFolder2: nestedFolder2,
          FilePath: filePath,
          MinFileSize: minFileSize,
          MaxFileSize: maxFileSize,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
          Type: type,
        },
        paramsSerializer: {
          indexes: true,
        },
        timeout: 100 * 1000,
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }
      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch case with id: ${id}`);
    }
  }

  async getCaseFilesFiltersForCase(
    id: string
  ): Promise<{ data: CaseFileFilters }> {
    try {
      const response = await this.api.get(`/api/Cases/${id}/files/filters`, {
        timeout: 100 * 1000,
      });
      return { data: response.data };
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch case filters for case with id: ${id}`);
    }
  }

  async updateCaseFlag(
    flagId: string,
    flagStatus: MigrationFlagStatus
  ): Promise<void> {
    try {
      const response: AxiosResponse<void> = await this.api.patch(
        `/api/Cases/updateFlag`,
        { flagId: flagId, flagStatus: flagStatus }
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to update status of case with id: ${flagId}`);
    }
  }
  async getCasesDeletionStatus(
    organizationId: string
  ): Promise<DeleteCaseRequest[]> {
    try {
      const response = await this.api.get(
        `/api/Cases/status/deletion/${organizationId}`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to fetch cases deletion status for organization with id: ${organizationId}`
      );
    }
  }
  async startCasesDeletion(
    organizationId: string,
    caseIds: string[]
  ): Promise<void> {
    try {
      const response: AxiosResponse<void> = await this.api.delete(
        `/api/Cases/${organizationId}/delete`,
        {
          data: {
            caseIds: caseIds,
          },
        }
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to start cases deletion with ids: ${caseIds}`);
    }
  }
}

export default CasesRepository;
