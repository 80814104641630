import { JobEntityType } from "./JobEntityType";
import { JobRequestStatus } from "./JobRequestStatus";
import { JobType } from "./JobType";

export abstract class JobRequest {
  id!: string;
  organizationId!: string;
  organizationName!: string;
  status!: JobRequestStatus;
  type!: JobType;
  migrationEntityId!: string;
  entityType!: JobEntityType;
  abstract getJobInfo(): { [key: string]: string };
}
export class DeleteCaseRequest extends JobRequest {
  type: JobType.DeleteEntity = JobType.DeleteEntity;
  entityType: JobEntityType = JobEntityType.Case;
  caseNumber!: string;
  getJobInfo(): { [key: string]: string } {
    return {
      "Case Number": this.caseNumber,
    };
  }
}
export function transformJobRequest(job: JobRequest): JobRequest {
  switch (job.type) {
    case JobType.DeleteEntity:
      if (isDeleteCaseRequest(job)) {
        return Object.assign(new DeleteCaseRequest(), job);
      }
      break;
    default:
      throw new Error(`Unknown job type: ${job.type}`);
  }
  throw new Error(`Invalid job data: ${JSON.stringify(job)}`);
}
export function isDeleteCaseRequest(job: JobRequest): job is DeleteCaseRequest {
  return (
    job.type === JobType.DeleteEntity && job.entityType === JobEntityType.Case
  );
}
