import { Box, Stack, Typography } from "@mui/joy";
import JobsTable from "../components/Jobs/JobsTable";

const JobsPage = () => {
  return (
    <>
      <Stack maxHeight={"92vh"}>
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2">Jobs</Typography>
        </Box>
        <JobsTable />
      </Stack>
    </>
  );
};

export default JobsPage;
