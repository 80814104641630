import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { AuthorizationResult } from "../models/AuthorizationResult";
import {useOrganization} from "../contexts/OrganizationContext";

const useApi = () => {
  const { accessToken, refreshToken, login } = useAuth();
  const navigate = useNavigate();
  const { organizationId } = useParams<{
    organizationId?: string;
  }>();

  const apiUrl = process.env.REACT_APP_API_URL ?? "/";
  const instance = axios.create({
    baseURL: apiUrl,
    timeout: 30000,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  
  // Check if token is expired before each request
  instance.interceptors.request.use(async (config) => {
    if (accessToken) {
      const decodedToken: any = jwtDecode(accessToken);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp < currentTime) {
        // Access token has expired, try to refresh it
        try {
          const response = await axios.post(`${apiUrl}api/refreshtoken`, {
            refreshToken: refreshToken,
          });
          const tokens = response.data as AuthorizationResult;

          // Update the access token in the auth context
          login(tokens.accessToken, tokens.refreshToken);

          // Update the access token in the request
          config.headers["Authorization"] = `Bearer ${tokens.accessToken}`;
        } catch (error) {
          alert("Session expired!");
          navigate("/login");
          console.error("Failed to refresh access token:", error);
        }
      }
    }

    if (organizationId)
      config.headers["Organization-ID"] = organizationId;
    
    return config;
  });

  return instance;
};

export default useApi;
