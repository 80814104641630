import { ColorPaletteProp } from "@mui/joy";

export enum JobRequestStatus {
  Pending = "pending",
  Processing = "processing",
  Failed = "failed",
  Completed = "completed",
  New = "new",
}

export const JobRequestStatusLabels: Record<JobRequestStatus, string> = {
  [JobRequestStatus.Pending]: "Pending",
  [JobRequestStatus.Processing]: "Processing",
  [JobRequestStatus.Failed]: "Failed",
  [JobRequestStatus.Completed]: "Completed",
  [JobRequestStatus.New]: "New",
};

export const JobRequestStatusColors: Record<
  JobRequestStatus,
  ColorPaletteProp
> = {
  [JobRequestStatus.Pending]: "neutral",
  [JobRequestStatus.Processing]: "primary",
  [JobRequestStatus.Failed]: "danger",
  [JobRequestStatus.Completed]: "success",
  [JobRequestStatus.New]: "lime",
};
