import { useState } from "react";
import { useParams } from "react-router-dom";
import OutlinedDiv from "../components/common/OutlinedDiv";
import {formatDate, formatTime, getFormattedDate} from "../helpers/StringFormatters";
import AuditLogTable from "../components/AuditLogs/AuditLogTable";
import ErrorSnackbar from "../components/common/ErrorSnackbar";
import {AuditLogQueryParams} from "../repositories/AuditLogsRepository";
import {AuditLogType} from "../models/AuditLog";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Switch,
  Typography,
  IconButton,
} from "@mui/joy";

import CloseIcon from '@mui/icons-material/Close';

const AuditLogsPage = () => {
  const auditLogsQueryKey = "auditLogs";
  const { organizationId } = useParams();
  
  const [fetchOrganizationConfigurationChanges, setFetchOrganizationConfigurationChanges] = useState(true);
  const [fetchClientConfigurationChanges, setFetchClientConfigurationChanges] = useState(true);
  const [fetchFileDownloads, setFetchFileDownloads] = useState(false);
  const [fetchEntityExports, setFetchEntityExports] = useState(false);
  const [fetchFileExports, setFetchFileExports] = useState(false);

  const [showErrorSnackbar, setShowErrorSnackbar] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const now = new Date();
  const today = new Date(now);
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 7);

  const [queryParams, setQueryParams] = useState<AuditLogQueryParams>({
    eventTypes: [AuditLogType.ClientConfigurationChange, AuditLogType.OrganizationConfigurationChange],
    fromDate: formatDate(yesterday),
    toDate: formatDate(today),
    fromTime: formatTime(yesterday),
    toTime: formatTime(today),
  });

  const [shouldFetchAuditLogs, setShouldFetchAuditLogs] = useState(true);

  const handleDateChange = (
    field: 'fromDate' | 'toDate'
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = event.target.value
      ? getFormattedDate(event.target.value, "yyyy-MM-dd")
      : null;
    
    setQueryParams((prev) => ({ ...prev, [field]: formattedDate }));
  };
  
  const handleTimeChange = (
    field: 'fromTime' | 'toTime'
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const handleResetToDefault = () => {
    setFetchOrganizationConfigurationChanges(true);
    setFetchClientConfigurationChanges(true);

    const eventTypes: AuditLogType[] = [];
    eventTypes.push(AuditLogType.ClientConfigurationChange);
    eventTypes.push(AuditLogType.OrganizationConfigurationChange);

    const now = new Date();
    const today = new Date(now);
    const yesterday = new Date(now);
    yesterday.setUTCDate(yesterday.getUTCDate() - 7);
    
    setQueryParams((prevState) => ({
      ...prevState,
      eventTypes: eventTypes,
      fromDate: formatDate(yesterday),
      toDate: formatDate(today),
      fromTime: formatTime(yesterday),
      toTime: formatTime(today),
    }));
  };
  
  const handleSubmit = () => {
    // validate filters
    if (!fetchOrganizationConfigurationChanges && !fetchClientConfigurationChanges) {
      setShowErrorSnackbar(true);
      setErrorMessage("At least one selected event type filter is required to search");
      return;
    }

    if (!queryParams.fromDate) {
      setShowErrorSnackbar(true);
      setErrorMessage("Start date is required");
      return;
    }

    if (!queryParams.toDate && queryParams.toTime !== '') {
      setShowErrorSnackbar(true);
      setErrorMessage("End Date is required when end time is provided");
      return;
    }

    // logic
    setShowErrorSnackbar(false);
    setShouldFetchAuditLogs(true);
    const eventTypes: AuditLogType[] = [];

    if (fetchOrganizationConfigurationChanges)
      eventTypes.push(AuditLogType.OrganizationConfigurationChange);

    if (fetchClientConfigurationChanges)
      eventTypes.push(AuditLogType.ClientConfigurationChange);
    
    setQueryParams((prev) => ({ 
      ...prev,
      eventTypes 
    }));
  };

  const handleCloseErrorSnackbar = () => {
    setShowErrorSnackbar(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mt: 1,
          mb: 0.5,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Audit Logs</Typography>
        <Stack direction="row" spacing={1}>
          <Button
            size="md"
            sx={{ width: 145 }}
            onClick={() => handleResetToDefault()}
          >
            Reset To Default
          </Button>
          <Button
            size="md"
            sx={{ width: 145 }}
            onClick={() => {
               handleSubmit()
            }}
          >
            Submit
          </Button>
        </Stack>
      </Box>
      
      <Card variant={"outlined"} sx={{ mb:1 }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography level="h4" component="div">
              Filters
            </Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 2 }} />
      
          <Grid container spacing={1} sx={{ flexGrow: 1, mb: 0.2 }}>
            <Grid xs={12} md={5} lg={5}>
              <OutlinedDiv label="Event Type">
                <Stack spacing={1} sx={{ mb: -0.5}}>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Organization Configuration Changes</FormLabel>
                    <Switch
                      checked={fetchOrganizationConfigurationChanges}
                      onChange={(e) => setFetchOrganizationConfigurationChanges(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Client Configuration Changes</FormLabel>
                    <Switch
                      checked={fetchClientConfigurationChanges}
                      onChange={(e) => setFetchClientConfigurationChanges(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    disabled
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>File Downloads</FormLabel>
                    <Switch
                      checked={fetchFileDownloads}
                      onChange={(e) => setFetchFileDownloads(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    disabled
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>Entity Exports</FormLabel>
                    <Switch
                      checked={fetchEntityExports}
                      onChange={(e) => setFetchEntityExports(e.target.checked)}
                    />
                  </FormControl>
                  <FormControl
                    disabled
                    orientation="horizontal"
                    sx={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <FormLabel>File Exports</FormLabel>
                    <Switch
                      checked={fetchFileExports}
                      onChange={(e) => setFetchFileExports(e.target.checked)}
                    />
                  </FormControl>
                  
                </Stack>
              </OutlinedDiv>
            </Grid>
            
            <Grid xs={12} md={7} lg={7}>
              <OutlinedDiv label="Range">
                  <Grid container spacing={2} sx={{ mt: 0.1 }}>
                    <Grid xs={6}>
                      <OutlinedDiv
                        label="Start Date"
                      >
                        <Stack direction="row" spacing={1}>
                          <Input
                            sx={{ width: "58%" }}
                            size={"sm"}
                            type="date"
                            value={
                              queryParams.fromDate
                                ? getFormattedDate(queryParams.fromDate, "yyyy-MM-dd")
                                : ""
                            }
                            onChange={handleDateChange('fromDate')}
                          />
                          <Input
                            sx={{ width: "42%" }}
                            size={"sm"}
                            type={"time"}
                            value={queryParams.fromTime}
                            onChange={handleTimeChange('fromTime')}
                            endDecorator={
                              <IconButton
                                sx={{ ml: -1  }}
                                onClick={() => setQueryParams({ ...queryParams, fromTime: '' })}>
                                <CloseIcon sx={{ color: 'white' }} />
                              </IconButton>
                            }
                          />
                        </Stack>
                      </OutlinedDiv>
                    </Grid>
      
                    <Grid xs={6}>
                      <OutlinedDiv
                        label="End Date"
                      >
                        <Stack direction="row" spacing={1}>
                          <Input
                            sx={{ width: "58%" }}
                            size={"sm"}
                            type="date"
                            value={
                              queryParams.toDate
                                ? getFormattedDate(queryParams.toDate, "yyyy-MM-dd")
                                : ""
                            }
                            onChange={handleDateChange('toDate')}
                          />
                          <Input
                            sx={{ width: "42%" }}
                            size={"sm"}
                            type={"time"}
                            value={queryParams.toTime}
                            onChange={handleTimeChange('toTime')}
                            endDecorator={
                              <IconButton
                                sx={{ ml: -1  }}
                                onClick={() => setQueryParams({ ...queryParams, toTime: '' })}>
                                <CloseIcon sx={{ color: 'white' }} />
                              </IconButton>
                            }
                          />
                        </Stack>
                      </OutlinedDiv>
                    </Grid>
                  </Grid>
              </OutlinedDiv>
            </Grid>
          </Grid>
          
        </CardContent>
      </Card>
      
      <AuditLogTable
        queryKey={auditLogsQueryKey}
        organizationId={organizationId ?? ""}
        queryParams={queryParams}
        shouldFetchAuditLogs={shouldFetchAuditLogs}
        setShouldFetchAuditLogs={setShouldFetchAuditLogs}
      />

      <ErrorSnackbar
        open={showErrorSnackbar}
        onClose={handleCloseErrorSnackbar}
        message={errorMessage}
      />
    </>
  )
};

export default AuditLogsPage;