import React from 'react';
import Snackbar from '@mui/joy/Snackbar';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

interface ErrorSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string | null;
  autoHideDuration?: number;
}

const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  open,
  onClose,
  message,
  autoHideDuration = 3000,
}) => {
  return (
    <Snackbar
      color="danger"
      variant="solid"
      sx={{ mr: 2 }}
      size="md"
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      startDecorator={
        <ErrorIcon sx={{ fontSize: 30 }} />
      }
      endDecorator={
        <IconButton size="sm" onClick={onClose}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      }
    >
      <Typography sx={{ color: 'white' }}>
        {message}
      </Typography>
    </Snackbar>
  );
};

export default ErrorSnackbar;
