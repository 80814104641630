import React from 'react';
import { Stack, Typography } from '@mui/joy';

type LabelValuePairProps = {
  label: string;
  value: string | number;
};

const LabelValuePair: React.FC<LabelValuePairProps> = ({ label, value }) => {
  return (
    <Stack direction="row" gap={0.5}>
      <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>
        {label}:
      </Typography>
      <Typography level="body-sm">
        {value}
      </Typography>
    </Stack>
  );
};

export default LabelValuePair;
