import useApi from "../services/ApiService";
import {getDateTimeString} from "../helpers/StringFormatters";
import { Paging } from "../models/Paging";
import {AuditLog, AuditLogType} from "../models/AuditLog";

export interface AuditLogQueryParams {
  eventTypes: AuditLogType[];
  fromDate?: string;
  fromTime?: string;
  toDate?: string;
  toTime?: string;
}

interface GetAuditLogsQueryOptions {
  params?: AuditLogQueryParams;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}

export class AuditLogsRepository {
  private api = useApi();
  
  async getAuditLogs(
    options: GetAuditLogsQueryOptions = {}
  ): Promise<{ data: AuditLog[]; paging: Paging | null }> {
    const {
      params = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;
    
    try {
      const fromDate = getDateTimeString(params?.fromDate, params?.fromTime);
      const toDate = getDateTimeString(params?.toDate, params?.toTime);
      const response = await this.api.get("/api/AuditLogs", {
        params: {
          eventTypes: params?.eventTypes,
          FromDate: fromDate,
          ToDate: toDate,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }

      return { data, paging };
    } catch (error) {
      throw new Error("Failed to fetch audit logs");
    }
  }
}
