import { Chip } from "@mui/joy";
import React from "react";
import {
  JobEntityType,
  JobEntityTypeColors,
  JobEntityTypeLabels,
} from "../../models/Jobs/JobEntityType";

type ChipBreakpoint = "sm" | "md" | "lg";

interface JobEntityTypeChipProps {
  type: JobEntityType;
  size?: ChipBreakpoint;
}

const JobTypeChip: React.FC<JobEntityTypeChipProps> = ({
  type,
  size = "sm",
}) => {
  return (
    <Chip variant="soft" size={size} color={JobEntityTypeColors[type]}>
      {JobEntityTypeLabels[type]}
    </Chip>
  );
};

export default JobTypeChip;
