import { Chip } from "@mui/joy";
import React from "react";
import {
  JobRequestStatus,
  JobRequestStatusColors,
  JobRequestStatusLabels,
} from "../../models/Jobs/JobRequestStatus";

type ChipBreakpoint = "sm" | "md" | "lg";

interface JobRequestStatusChipProps {
  status: JobRequestStatus;
  size?: ChipBreakpoint;
}

const JobRequestStatusChip: React.FC<JobRequestStatusChipProps> = ({
  status,
  size = "sm",
}) => {
  return (
    <Chip variant="soft" size={size} color={JobRequestStatusColors[status]}>
      {JobRequestStatusLabels[status]}
    </Chip>
  );
};

export default JobRequestStatusChip;
