import { Box, Chip } from "@mui/joy";
import React from "react";
import { JobRequest } from "../../models/Jobs/JobRequest";
import { JobEntityTypeColors } from "../../models/Jobs/JobEntityType";

type ChipBreakpoint = "sm" | "md" | "lg";

interface JobInfoViewpProps {
  jobRequest: JobRequest;
  size?: ChipBreakpoint;
}

const JobInfoView: React.FC<JobInfoViewpProps> = ({
  jobRequest,
  size = "sm",
}) => {
  return (
    <Box>
      {Object.entries(jobRequest.getJobInfo()).map(([key, value]) => (
        <Chip
          key={key}
          variant="soft"
          size={size}
          color={JobEntityTypeColors[jobRequest.entityType]}
        >
          {key}: {value}
        </Chip>
      ))}
    </Box>
  );
};

export default JobInfoView;
