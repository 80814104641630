export enum AuditLogType {
  ClientConfigurationChange = "clientConfigurationChange",
  OrganizationConfigurationChange = "organizationConfigurationChange",
}

export const AuditLogTypeLabels: Record<AuditLogType, string> = {
  [AuditLogType.ClientConfigurationChange]: "Client Configuration Change",
  [AuditLogType.OrganizationConfigurationChange]: "Organization Configuration Change",
};

export interface AuditLog {
  id: string;
  entityId: string;
  eventType: AuditLogType;
  oldValue: string | null;
  newValue: string | null;
  performedBy: string;
  eventDate: string;
  performerEmailAddress: string;
}